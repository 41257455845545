body {
	font-family: var(--font-family), sans-serif;
	margin: 0;
}

html,
body {
	height: 100%;
}

p-dropdown {
	.p-dropdown-filter {
		width: 100% !important;
		border: 1px solid rgba(255, 255, 255, 0.3) !important;
	}
}

::ng-deep {
	.p.button.p-button-success {
		background: var(--green-500) !important;
	}

	.p.button.p-button-warning {
		background: var(--yellow-500) !important;
	}
}

.flex-responsive {
	display: flex;

	@media screen and (max-width: 767px) {
		flex-direction: column;
	}
}

.visible-mobile {
	display: none;

	@media screen and (max-width: 767px) {
		display: block;
	}
}

.page-overlay-text {
	position: absolute;
	width: 100vw;
	height: 100vh;
	backdrop-filter: blur(20px);
	top: 0;
	left: 0;
	z-index: 100;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
